import {
  FragmentMenuFragment,
  FragmentMenuPartnersFragment,
  ImageFieldsFragment,
  ImageStyleAvailable
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import Link from "next/link";
import DrupalImage from "@/components/atoms/DrupalImage.tsx";
import {getVariation} from "@/helpers/image.ts";
import PartnerLink from "@/components/atoms/PartnerLink.tsx";

interface FooterNavSectionProps {
  children?: React.ReactNode;
  navigation?: FragmentMenuPartnersFragment;
}

export const PartnersNavSection = ({
  navigation,
}: FooterNavSectionProps) => {
  return (
      <nav role="navigation">
          <ul className="flex flex-wrap gap-4 xl:gap-6 items-center lg:justify-end">
              {navigation?.items?.map((item, index) => (
                  <li
                      key={index}
                  >
                      <PartnerLink image={item.extras?.logo} href={item.url}/>
                      {item.children &&
                        <ul>
                            {item.children?.map((item, index) => (
                                <li
                                    key={index}
                                >
                                  <PartnerLink image={item.extras?.logo} href={item.url}/>
                                </li>
                            ))
                            }
                        </ul>
                      }
                  </li>
              ))}
          </ul>
      </nav>
  );
};
