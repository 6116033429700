import type {FragmentMenuFragment} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import Link from "next/link";

interface FooterNavSectionProps {
  children?: React.ReactNode;
  navigation?: FragmentMenuFragment;
  title?: string;
}

export const LegalNavSection = ({
  title,
  navigation,
}: FooterNavSectionProps) => {

  const displayTitle = title ?? navigation?.name;
  const currentYear = new Date().getFullYear();
  return (
      <nav role="navigation">
          <ul className="md:items-center io-grid gap-1 md:gap-2">
              {/*<li>© {currentYear}</li>*/}
              {navigation?.items?.map((item, index) => (
                  <li
                      key={index}
                      className="col-span-full sm:col-span-6 md:col-span-4 lg:col-span-6"
                  >
                      <Link href={item.url} className="text-gray-600 hover:text-blue text-sm transition-colors flex-grow"
                            prefetch={false}>
                          {item.title}
                      </Link>
                  </li>
              ))}
              <li className={"col-span-full sm:col-span-6 md:col-span-4 lg:col-span-6 cookiesettings"}>
              </li>
          </ul>
      </nav>
  );
};
