import {SocialLink} from "@/components/atoms/SocialLink";
import {cn} from "@/helpers/className";
import {FragmentSocialMediaLinksItemFragment} from "@/graphql/sdk/__generated__";

interface FooterSocialLinksProps {
  className?: string;
  socialMediaLinksItems?: FragmentSocialMediaLinksItemFragment
}

export const FooterSocialLinks = ({className, socialMediaLinksItems}: FooterSocialLinksProps) => {
  return (
    <div className={cn("flex gap-2 lg:gap-4", className)}>
      {socialMediaLinksItems?.facebookUrl?.url &&
        <SocialLink
          type="facebook"
          url={socialMediaLinksItems.facebookUrl.url}
        />
      }
      {socialMediaLinksItems?.instagramUrl?.url &&
        <SocialLink
          type="instagram"
          url={socialMediaLinksItems.instagramUrl.url}
        />
      }
      {socialMediaLinksItems?.linkedinUrl?.url &&
        <SocialLink
          type="linkedin"
          url={socialMediaLinksItems.linkedinUrl.url}
        />
      }
      {socialMediaLinksItems?.twitterUrl?.url &&
        <SocialLink
          type="twitter"
          url={socialMediaLinksItems.twitterUrl.url}
        />
      }
      {socialMediaLinksItems?.youtubeUrl?.url &&
        <SocialLink
          type="youtube"
          url={socialMediaLinksItems.youtubeUrl.url}
        />
      }
    </div>
  );
};