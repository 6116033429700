export const Logo = () => {
  return (
      <>
          <span className="sr-only">VRT logo</span>
          <svg viewBox="0 0 41 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="group w-[50px] lg:w-[60px]">
              <path
                  d="M35.364 2.64153C34.6868 2.03555 34.2947 1.12565 34.2196 0H30.1373C30.1661 0.81294 30.3049 1.61823 30.55 2.39388C30.9428 3.65638 31.6656 4.79109 32.6437 5.68078C33.5998 6.5308 34.7657 7.10985 36.0207 7.358C36.5831 7.473 37.1557 7.5302 37.7298 7.52873H39.0318V3.44637H37.7373C36.7842 3.44637 35.9681 3.17246 35.3715 2.63777"
                  fill="currentColor"></path>
              <path
                  d="M27.4713 4.33188C22.5222 4.33188 16.2036 5.42188 17.8245 19.8734H22.2146C22.0457 17.9973 21.9219 16.4589 21.9088 14.9524C21.9088 10.658 23.0344 8.16284 27.4076 8.34107L28.3043 8.37484V4.37878C28.0098 4.34501 27.5651 4.33376 27.4713 4.33188Z"
                  fill="currentColor"></path>
              <path
                  d="M13.0386 4.37878C12.4251 6.63008 11.6466 9.13653 10.8717 11.461C10.3089 13.1495 9.13262 16.3388 9.13262 16.3388H8.94501C8.32027 14.7029 7.77433 13.1495 7.20588 11.461C6.41417 9.13841 5.59995 6.63196 4.99022 4.37878H0.28125C1.10485 6.9659 2.00725 9.54927 3.00532 12.1777C4.0034 14.8061 4.96583 17.3707 6.114 19.8696H11.7929C12.9298 17.3669 13.8904 14.8042 14.8941 12.1777C15.8978 9.55115 16.802 6.9659 17.6257 4.37878H13.0386Z"
                  fill="currentColor"></path>
              <path
                  d="M39.0243 15.3351C38.2269 15.7347 37.2401 16.0105 36.561 16.0105C35.2815 16.0105 34.5629 15.5565 34.3097 14.0068C34.1221 12.8568 34.0451 10.5023 34.2646 8.37859H30.066C29.8596 10.9113 29.8465 12.4722 30.1767 14.686C30.6963 18.1793 32.8032 20.0947 36.3321 20.0704C37.6153 20.0704 38.7222 19.8039 40.2812 18.986L39.0243 15.3351Z"
                  fill="currentColor"></path>
              <path
                  d="M35.364 2.64153C34.6868 2.03555 34.2947 1.12565 34.2196 0H30.1373C30.1661 0.81294 30.3049 1.61823 30.55 2.39388C30.9428 3.65638 31.6656 4.79109 32.6437 5.68078C33.5998 6.5308 34.7657 7.10985 36.0207 7.358C36.5831 7.473 37.1557 7.5302 37.7298 7.52873H39.0318V3.44637H37.7373C36.7842 3.44637 35.9681 3.17246 35.3715 2.63777"
                  fill="currentColor"></path>
              <path
                  d="M27.4713 4.33188C22.5222 4.33188 16.2036 5.42188 17.8245 19.8734H22.2146C22.0457 17.9973 21.9219 16.4589 21.9088 14.9524C21.9088 10.658 23.0344 8.16284 27.4076 8.34107L28.3043 8.37484V4.37878C28.0098 4.34501 27.5651 4.33376 27.4713 4.33188Z"
                  fill="currentColor"></path>
              <path
                  d="M13.0386 4.37878C12.4251 6.63008 11.6466 9.13653 10.8717 11.461C10.3089 13.1495 9.13262 16.3388 9.13262 16.3388H8.94501C8.32027 14.7029 7.77433 13.1495 7.20588 11.461C6.41417 9.13841 5.59995 6.63196 4.99022 4.37878H0.28125C1.10485 6.9659 2.00725 9.54927 3.00532 12.1777C4.0034 14.8061 4.96583 17.3707 6.114 19.8696H11.7929C12.9298 17.3669 13.8904 14.8042 14.8941 12.1777C15.8978 9.55115 16.802 6.9659 17.6257 4.37878H13.0386Z"
                  fill="currentColor"></path>
              <path
                  d="M39.0243 15.3351C38.2269 15.7347 37.2401 16.0105 36.561 16.0105C35.2815 16.0105 34.5629 15.5565 34.3097 14.0068C34.1221 12.8568 34.0451 10.5023 34.2646 8.37859H30.066C29.8596 10.9113 29.8465 12.4722 30.1767 14.686C30.6963 18.1793 32.8032 20.0947 36.3321 20.0704C37.6153 20.0704 38.7222 19.8039 40.2812 18.986L39.0243 15.3351Z"
                  fill="currentColor"></path>
          </svg>
      </>
  );
};
