import Link from "next/link";

import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";

interface UserProfileLinkProps {
  className?: string;
  target: "_blank" | "_self" | "_parent" | "_top";
  type: "users" | "profile";
  url: string;
}

export const UserProfileLink = ({url, className, type, target}: UserProfileLinkProps) => {
  return (
    <Link
      href={url}
      className={cn(
        "inline-flex h-8 w-8 hover:bg-grey shrink-0 items-center justify-center rounded-full transition-colors group",
        className,
      )}
      target={target}
      rel="noopener noreferrer"
      aria-label={"User Profile"}
      prefetch={false}
    >
      <Icon name={type} className="h-5 w-5 group-hover:!fill-white transition-colors"/>
    </Link>
  );
};
