import Link from "next/link";
import DrupalImage from "@/components/atoms/DrupalImage.tsx";
import { ImageStyleAvailable} from "@/graphql/sdk/__generated__";
import {getVariation} from "@/helpers/image.ts";

interface FaqLinkProps {
  href: string | null | undefined;
  image: object
}

export const SvgLink = ({href, svg}: FaqLinkProps) => {
  return (
    <Link
      href={href}
      className="flex items-center justify-between gap-4"
    >
      {svg?.svgMediaImage?.url && (
        <img src={svg.svgMediaImage.url} alt={svg.svgMediaImage.alt} />
      )}
    </Link>
  );
};

export default SvgLink;
