import { Fragment, useEffect } from "react";
import { usePathname } from "next/navigation";
import { Dialog, Transition } from "@headlessui/react";

import Icon from "@/components/atoms/Icon";
import { MobileMenuLink } from "@/components/atoms/MobileMenuLink";
import { SubNavGroup } from "@/components/molecules/SubNavGroup";
import { FragmentMenuFragment } from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className.ts";

interface MobileMenuProps {
  mainMenu: FragmentMenuFragment;
  mobileMenuOpen: boolean;
  secondaryMenu: FragmentMenuFragment;
  setMobileMenuOpen: (open: boolean) => void;
}

export const MobileMenu = ({
                             mobileMenuOpen,
                             mainMenu,
                             secondaryMenu,
                             setMobileMenuOpen,
                           }: MobileMenuProps) => {
  const pathname = usePathname();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname, setMobileMenuOpen]);

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 xl:hidden"
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50 bg-grey/80 backdrop-blur animate-fadeIn" />
          <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-150 sm:duration-300 delay-[.15s]"
              enterFrom="opacity-0 scale-0"
              enterTo="opacity-100 scale-100"
              leave="transform transition ease-in-out duration-150 delay-0"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-0"
          >
          <button
              type="button"
              className={cn(
              `btn-mobile-nav-close group fixed top-2 p-0 right-4 z-[51] bg-white border-2 border-midnight-700/40 text-midnight-700 w-9 h-9 rounded-full inline-flex items-center justify-center`,
              )}
              onClick={() => setMobileMenuOpen(false)}
              aria-label={"Sluit navigatie"}
          >
              <Icon name="close" className="h-4 w-4" />
          </button>
          </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-150 sm:duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-150 sm:duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between gap-4 h-[var(--header-height)]"></div>
            <div className="flex px-6 py-6 flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                {mainMenu?.items?.map((item, index) => (
                  <Fragment key={item.id}>
                    {item.children?.length ? (
                      <SubNavGroup
                        item={item}
                        subNav={item.children}
                        linkClassName="text-sm"
                        viewMode={item.view_mode}
                        subNavClassName="pt-3"
                        clickToOpen={true}
                      />
                    ) : (
                      <MobileMenuLink item={item} />
                    )}
                  </Fragment>
                ))}
              </div>

              <div className="flex flex-col gap-4">
                {secondaryMenu?.items?.map((item) => (
                  <MobileMenuLink item={item} key={item.id} />
                ))}
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};
