"use client";

import { Fragment, useRef, useState } from "react";
import Link from "next/link";
import { Logo } from "@/components/atoms/Logo";
import { usePathname } from "next/navigation";
import { FragmentMenuFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { useScrollDirection } from "@/lib/hooks/useScrollDirection";
import { SubNavGroup } from "@/components/molecules/SubNavGroup";
import {UserProfileLink} from "@/components/atoms/UserProfileLink.tsx";
import {SearchElement} from "@/components/blocks/SearchElement.tsx";
import { MobileMenu } from "@/components/molecules/MobileMenu";
import Icon from "@/components/atoms/Icon.tsx";
interface HeaderProps {
  mainMenu?: FragmentMenuFragment;
  secondaryMenu?: FragmentMenuFragment;
}
export const Header = ({
     mainMenu,
     secondaryMenu,
    }: HeaderProps) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const pathname = usePathname();
    const { scrollDirection, scrollY } = useScrollDirection();
    const headerTransparent = false;
    const headerHiddenClass = "header--hidden !top-[-100%] duration-300";
    const headerNormalClass = "top-0 duration-300";
    const headerCondensedClass = "top-0 duration-300";
  return (
    <header
        className={cn(
            "header z-50 trans-timing-default transition-all fixed inset-x-0",
            headerTransparent ? 'bg-transparent text-white' : 'bg-white border-b border-grey-100',
            scrollY > 200 ? headerCondensedClass : headerNormalClass,
            scrollDirection === "down" && scrollY >= 200 ? headerHiddenClass : "",
            searchOpen ? headerHiddenClass : "",
        )}
        role="banner">
      <div
          className={cn(
              "h-full",
              scrollDirection === "down" ? "" : "shadow-sm",
          )}>
        <div className="container mx-auto flex items-center justify-between h-full">
            <div className="hover:text-blue-500 transition-colors">
              <Link href={`/`}>
                <Logo/>
              </Link>
            </div>

          <nav
            className="hidden lg:block h-full"
            role="navigation"
            aria-label="Main Navigation Desktop">
            <ul className="flex gap-1 h-full">
              {mainMenu?.items?.map((item) => (
                <li
                    key={item.id}
                    className="group static flex items-center"
                >
                  <Fragment key={item.id}>
                    {item.children?.length ? (
                      <SubNavGroup
                        item={item}
                        subNav={item.children}
                        itemClassName={cn(
                          "main-nav-w-sub inline-flex items-center text-grey font-semibold h-full text-sm transition-colors",
                          pathname === item.url
                            ? "main-nav-w-sub--active"
                            : "",
                        )}
                        viewMode={item.view_mode}
                        className="static"
                        linkClassName="whitespace-nowrap hover:text-blue transition-colors "
                        subNavClassName="absolute top-full inset-x-0 bg-white shadow-lg py-4 lg:py-8 -z-10 w-full"
                      />
                    ) : (
                      <Link
                        href={item.url}
                        className={cn(
                          "inline-flex items-center hover:text-white hover:bg-blue font-semibold transition-colors btn py-1 px-3 rounded-full text-sm",
                          pathname === item.url
                            ? "bg-midnight text-white"
                            : "text-grey",
                        )}
                      >
                        {item.title}
                      </Link>
                    )}
                  </Fragment>
                </li>
              ))}
            </ul>
          </nav>

          {secondaryMenu?.items && (
            <div>
              <div className="flex lg:gap-2 justify-end">
                  <SearchElement
                      setSearchOpen={setSearchOpen}
                      searchOpen={searchOpen}
                  />
                  <UserProfileLink
                      target="_blank"
                      type="profile"
                      aria-label={"VRT Profiel"}
                      url="https://profiel.vrt.be/"
                  />
                  <button
                      type="button"
                      className="btn inline-flex h-8 w-8 items-center justify-center lg:hidden"
                      onClick={() => setMobileMenuOpen(true)}
                  >
                      <span className="sr-only">Open menu</span>

                      <span className="flex w-5 h-5 flex-col">
                        <Icon name="hamburger" className="h-5 w-5 group-hover:!fill-white transition-colors"/>
                      </span>
                  </button>
                  <ul className="gap-1 lg:gap-2 xl:gap-3 items-center hidden lg:flex">
                    {secondaryMenu?.items?.map((item) => (
                        <li
                            key={item.id}
                            className="group relative">
                          <Link
                            key={item.id}
                            href={item.url}
                            className={cn(
                              "flex gap-1 text-md hover:text-blue",
                              pathname === item.url
                            )}
                          >
                            {item.title}
                          </Link>
                        </li>
                    ))}
                  </ul>
              </div>
            </div>
          )}
        </div>
      </div>
        <MobileMenu
            mobileMenuOpen={mobileMenuOpen}
            mainMenu={mainMenu}
            secondaryMenu={secondaryMenu}
            setMobileMenuOpen={setMobileMenuOpen}
        />
    </header>
  );
};
