import Link from "next/link";
import Icon from "@/components/atoms/Icon";
import DrupalImage from "@/components/atoms/DrupalImage.tsx";
import {ImageFieldsFragment, ImageStyleAvailable} from "@/graphql/sdk/__generated__";
import {getVariation} from "@/helpers/image.ts";

interface PartnerLinkProps {
  href: string | null | undefined;
  image: object
}

export const PartnerLink = ({href, image}: PartnerLinkProps) => {
  const media = image?.imageMediaImage as ImageFieldsFragment;
  if (!media?.variations) {
    return <></>;
  }
  const imageStyle = ImageStyleAvailable.LandscapeRatio32;

  const photo = getVariation(media.variations, imageStyle);
  return (
    <Link
      href={href}
      className="flex items-center justify-between gap-4"
    >
      <DrupalImage
        url={photo.url}
        alt={media.alt || "VRT partner"}
        width="100"
        height={photo.height}
        className={"mx-auto w-auto max-w-full"}
        priority
      />
    </Link>
  );
};

export default PartnerLink;
