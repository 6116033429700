import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/assets/styles/app.css");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/BannerButton.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/DrupalImage.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/ListButton.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/blocks/Footer.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/blocks/Header.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/NewsOverviewListWithoutFilters.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/TeaserContent.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/ArticleSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/MaxSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/NwsSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/PodcastSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/RelatedSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/SporzaSlider.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/paragraphs/AccordeonParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/paragraphs/VideoParagraph.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/BannerVisibilityTracker.tsx");
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/VerticalListVisibilityTracker.tsx")